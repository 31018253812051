import { differenceInDays, format, parseISO } from 'date-fns';
import { API_ENDPOINT } from '../../config/api';
import useSWRImmutable from 'swr/immutable';

export type PatientDetails = {
    due_data:     DueDatum[];
    proc_data:    null;
    revisitdata:  Revisitdatum[] | null;
    transactions: null;
}

export type DueDatum = {
    due_amount: number;
    due_txn_id: number | null;
}

export type Revisitdatum = {
    free_revisit_enabled:  boolean;
    free_revisit_period:   number;
    last_appointment_date: string;
}

const fetcher = (url: string) => fetch(url).then(res => res.json());

export function useCalculateFreeVisitPeriod(appointmentId: number) {
    const { data: resData, error } = useSWRImmutable<PatientDetails[]>(
        appointmentId ? `${API_ENDPOINT}/Transaction/web_admin/get_apmnt_transactions/${appointmentId}` : null,
        fetcher
    );

    if (error) return { error };
    if (!resData) return { isLoading: true };

    const fetchedTransactions = resData.length ? resData : [];
    const revisitdata = fetchedTransactions[0]?.revisitdata?.[0];

	if (!revisitdata?.free_revisit_enabled || !revisitdata?.last_appointment_date) {
        return {};
    }

    const lastAppointmentDate = parseISO(revisitdata.last_appointment_date);
    const diff = differenceInDays(new Date(), lastAppointmentDate);
    const lastVisit = format(lastAppointmentDate, 'dd-MM-yyyy');

    return revisitdata.free_revisit_period && diff <= revisitdata.free_revisit_period
        ? { status: true, diff, lastVisit }
        : { status: false, diff, lastVisit };
}

export default 'No default exports available for func.js';
