import * as React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons"
import { DayPicker } from "react-day-picker"

import { cn } from "@/lib/utils"
import { buttonVariants } from "@/components/ui/button"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("_p-3", className)}
      classNames={{
        months: "_flex _flex-col sm:_flex-row _space-y-4 sm:_space-x-4 sm:_space-y-0",
        month: "_space-y-4",
        caption: "_flex _justify-center _pt-1 _relative _items-center",
        caption_label: "_text-sm _font-medium",
        nav: "_space-x-1 _flex _items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "_h-7 _w-7 _bg-transparent _p-0 _opacity-50 hover:_opacity-100"
        ),
        nav_button_previous: "_absolute _left-1",
        nav_button_next: "_absolute _right-1",
        table: "_w-full _border-collapse _space-y-1",
        head_row: "_flex",
        head_cell:
          "_text-muted-foreground _rounded-md _w-8 _font-normal _text-[0.8rem]",
        row: "_flex _w-full _mt-2",
        cell: cn(
          "_relative _p-0 _text-center _text-sm focus-within:_relative focus-within:_z-20 [&:has([aria-selected])]:_bg-accent [&:has([aria-selected].day-outside)]:_bg-accent/50 [&:has([aria-selected].day-range-end)]:_rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:_rounded-r-md [&:has(>.day-range-start)]:_rounded-l-md first:[&:has([aria-selected])]:_rounded-l-md last:[&:has([aria-selected])]:_rounded-r-md"
            : "[&:has([aria-selected])]:_rounded-md"
        ),
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "_h-8 _w-8 _p-0 _font-normal aria-selected:_opacity-100"
        ),
        day_range_start: "_day-range-start",
        day_range_end: "_day-range-end",
        day_selected:
          "_bg-primary _text-primary-foreground hover:_bg-primary hover:_text-primary-foreground focus:_bg-primary focus:_text-primary-foreground",
        day_today: "_bg-accent _text-accent-foreground",
        day_outside:
          "_day-outside _text-muted-foreground _opacity-50 _ aria-selected:_bg-accent/50 aria-selected:_text-muted-foreground aria-selected:_opacity-30",
        day_disabled: "_text-muted-foreground _opacity-50",
        day_range_middle:
          "aria-selected:_bg-accent aria-selected:_text-accent-foreground",
        day_hidden: "_invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeftIcon className="_size-4" />,
        IconRight: ({ ...props }) => <ChevronRightIcon className="_size-4" />,
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
