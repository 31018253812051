import type {
  ClinicData,
  FullUser,
  Preferences,
  PreferencesExtended,
  ScheduleWithClinicId,
  StaffList,
} from '@/types/user';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist, createJSONStorage } from 'zustand/middleware';
import { STORAGE_DOMAIN } from '@/config/api';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';

type State = {
  user: FullUser | null;
  schedule: Schedule | null;
};

type Actions = {
  setUser: (user: FullUser) => any;
  getSelectedClinic: () => ClinicData | undefined;
  setSelectedClinic: (clinicId: string | number, clinicNo?: string) => any;
  getAllClinics: () => ClinicData[];
  isProvider: () => boolean;
  isClinic: () => boolean;
  logout: () => any;
  setSelectedProviderId: (providerId?: number) => any;
  setSelectedSpecialityId: (specialityId?: number) => any;
  setStaffList: (staffList: StaffList) => any;
  setProviderSignUrl: (file_url?: string) => any;
  setPreference: (
    preference: Partial<Preferences | PreferencesExtended>
  ) => void;
  setScheduleList: (scheduleList: ScheduleWithClinicId[]) => void;
  setSchedule: (scheduleId: number | string) => void;
  getProviderSignUrl: () => string | undefined;
};

type Schedule = {
  schedule_id: number;
  provider_id: number;
  slot_start: string; // "06:00:00"
  slot_end: string; // "23:30:00"
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
};

// TODO: Implement Test cases
export const setInitialSchedule = (schedules: Schedule[]): Schedule | null => {
  // First check if schedule is active on this day.
  // Sort schedules by start time.
  // if current time is between start and end time of schedule, then return it.
  // else if any schedule start/end time is within + / -  1 hour of current time return it.
  // if 2 schedules are within 1 hour of current time, return the upcoming schedule.
  // else return the first schedule.
  const currentMoment = moment();
  const currentDay = currentMoment.format('ddd').toLowerCase();
  const schedulesSorted = schedules
    .filter((s) => s[currentDay as keyof typeof s] === true)
    .sort((a, b) =>
      moment(a.slot_start, 'HH:mm:ss').diff(moment(b.slot_start, 'HH:mm:ss'))
    );

  if (schedulesSorted.length === 0) return schedules.length > 0 ? schedules[0] : null;

  for (const schedule of schedulesSorted) {
    const startTime = moment(schedule.slot_start, 'HH:mm:ss');
    const endTime = moment(schedule.slot_end, 'HH:mm:ss');

    if (currentMoment.isBetween(startTime, endTime, undefined, '[]')) {
      return schedule;
    }
  }

  const oneHourAhead = currentMoment.clone().add(1, 'hour');
  const oneHourBehind = currentMoment.clone().subtract(1, 'hour');

  const nearbySchedules = schedulesSorted.filter((schedule) => {
    const startTime = moment(schedule.slot_start, 'HH:mm:ss');
    const endTime = moment(schedule.slot_end, 'HH:mm:ss');
    return (
      startTime.isBetween(oneHourBehind, oneHourAhead, undefined, '[]') ||
      endTime.isBetween(oneHourBehind, oneHourAhead, undefined, '[]')
    );
  });

  if (nearbySchedules.length > 0) {
    const upcomingSchedule = nearbySchedules.find((schedule) =>
      moment(schedule.slot_start, 'HH:mm:ss').isAfter(currentMoment)
    );
    return upcomingSchedule || nearbySchedules[0];
  }

  return schedulesSorted[0];
};

export const useFullUser = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      user: null,
      schedule: null,
      setUser: (user: FullUser) =>
        set({
          user,
          schedule: setInitialSchedule(user.schedules),
        }),
      getSelectedClinic: () => {
        const user = get().user;
        const cd = user?.clinic_data;
        if (user?.type !== 'pharmacy') {
          const selectedClinic = user?.preferences.selectedClinicId;
          return Array.isArray(cd)
            ? selectedClinic
              ? cd.find((c) => c.clinic_id === selectedClinic)
              : cd[0]
            : cd;
        }
        return undefined;
      },
      setSelectedClinic: (clinicId, clinicNo) =>
        set((state) => {
          if (state.user && state.user.type !== 'pharmacy') {
            const _clinicId = Number(clinicId);
            const _clinicNo =
              (clinicNo ??
                get()
                  .getAllClinics()
                  .find((c) => c.clinic_id === _clinicId)?.clinic_no) ||
              '';
            state.user.preferences.selectedClinicId = _clinicId;
            state.user.preferences.selectedClinicNo = _clinicNo;
          }
        }),
      logout: () => {
        window.location.href = '/';
        set({ user: null });
      },
      getAllClinics: () => {
        const cd = get().user?.clinic_data;
        return Array.isArray(cd) ? cd : cd ? [cd] : [];
      },
      isProvider: () => get().user?.user_data.user_type === 'provider',
      isClinic: () => get().user?.type === 'clinic',
      setSelectedProviderId: (providerId) =>
        set((state) => {
          if (state.user && state.user.type === 'clinic') {
            if (state.user.preferences.bookingPage)
              state.user.preferences.bookingPage.selectedProviderId =
                providerId;
            else
              state.user.preferences.bookingPage = {
                selectedProviderId: providerId,
              };
          }
        }),
      setSelectedSpecialityId: (specialityId) =>
        set((state) => {
          if (state.user && state.user.type === 'clinic') {
            if (state.user.preferences.bookingPage)
              state.user.preferences.bookingPage.selectedSpecialityId =
                specialityId;
            else
              state.user.preferences.bookingPage = {
                selectedSpecialityId: specialityId,
              };
          }
        }),
      setStaffList: (staffList) =>
        set((state) => {
          if (state.user && state.user.type === 'clinic') {
            state.user.staff_list = staffList;
          }
        }),
      setProviderSignUrl: (file_url) =>
        set((state) => {
          if (state.user && state.user.type === 'provider') {
            state.user.user_data.sign_image = file_url
              ? STORAGE_DOMAIN + file_url
              : '';
          }
        }),
      setPreference: (preference) =>
        set((state) => {
          if (state.user && state.user.type !== 'pharmacy') {
            state.user.preferences = {
              ...state.user.preferences,
              ...preference,
            };
          }
        }),
      setScheduleList: (scheduleList: ScheduleWithClinicId[]) =>
        set((state) => {
          if (state.user && state.user.type === 'provider') {
            state.user.schedules = scheduleList;
            const sc  = setInitialSchedule(scheduleList);
			console.log({sc})
            state.schedule = sc;
          }
        }),
      setSchedule: (scheduleId: number | string) =>
        set((state) => {
          if (state.user && state.user.type === 'provider') {
            state.schedule =
              get().user?.schedules.find(
                (e) => e.schedule_id === Number(scheduleId)
              ) || null;
          }
        }),
      getProviderSignUrl: () => {
        const user = get().user;
        if (user?.type === 'provider')
          return STORAGE_DOMAIN + user?.user_data.sign_image;
      },
    })),
    {
      name: 'current-user',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useUser = () =>
  useFullUser((state) => ({ ...state.user!, isProvider: state.isProvider(), isClinic: state.isClinic() }));

export const useClinic = () =>
  useFullUser((state) => state.getSelectedClinic());

export const useSelectedDetails = () =>
  useFullUser(({ setSelectedProviderId, setSelectedSpecialityId }) => ({
    setSelectedProviderId,
    setSelectedSpecialityId,
  }));

export const usePageDetails = () => {
  const user = useUser()!;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return {
    ...user,
    pathname,
    navigate,
    user,
  };
};
