import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Axios from '@/_helpers/axios_interceptor';
import cn from 'classnames';

import { API_ENDPOINT, STORAGE_DOMAIN } from '../../config/api';
import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import useInterval from '../../_helpers/use_interval_hook';
import ConfirmAction from '../layout/ConfirmAction';
import { getBreakTime } from '../../_helpers/misc';
import { toAmPm, formatTime } from '../../_helpers/common_utils';
import showCurrency from '../common/showCurrency';
import classNames from 'classnames';
import { useUser } from '@/hooks/useUser';
import { useGeolocation } from '@/hooks/geolocation';
import getDistance from 'geolib/es/getDistance';
import { addMinutes, format, isToday, parse } from 'date-fns';
import { calculateTime, parseTime } from '@/_helpers/customFuncs';

let lastUpdatedTime = null;
let lastAppntProcMinutes = 0;
const week = {
  monday_on: 'M',
  tuesday_on: 'T',
  wednesday_on: 'W',
  thursday_on: 'T',
  friday_on: 'F',
  saturday_on: 'S',
  sunday_on: 'S',
};

const isKerala = (pinCode) => {
  // pincode range from 670xxx to 695xxx are inside kerala
  if (
    !isNaN(Number(pinCode)) &&
    Number(pinCode) >= 670000 &&
    Number(pinCode) <= 695999
  )
    return true;
};

const Live = () => {
  const { person_no } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const [hasOtherBookings, setHasOtherBookings] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [lastRefreshedTime, setLastRefreshedTime] = useState(null);
  const [refreshtimeFromNow, setRefreshtimeFromNow] = useState(null);
  const [cancelAppointmentID, setCancelAppointmentID] = useState(undefined);
  const [cancelAppClinicNo, setCancelAppClinicNo] = useState(undefined);
  const [cancelScheduleId, setCancelScheduleId] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const billedAppoinments = useRef([]);
  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  // change page title when this page is loaded
  useEffect(() => {
    const { title } = document;
    document.title = 'UNQ Live Tracking';

    return () => {
      document.title = title;
    };
  }, []);

  const loadPatientBookings = async () => {
    if (!person_no) return;
    setLoading(true);
    lastUpdatedTime = null;
    lastAppntProcMinutes = 0;
    // setShowLoading(true);
    setPatientDetails({});
    await Axios.get(
      `${API_ENDPOINT}/PatientAppointments/load_new_bookings_for_live_page?person_no=UIN${person_no}`
    )
      .then((res) => {
        if (res.data) {
          const _appointments =
            res.data?.appointments.filter(
              (k) =>
                k.provider_consultation_status === 'not done' ||
                (k.provider_consultation_status === 'done' &&
                  k.is_patient_absent)
            ) || [];
          setPatientDetails(res.data.patient || {});
          setHasOtherBookings(
            !!(
              res.data.other_bookings_count && res.data.other_bookings_count > 0
            )
          );
          for (let i = 0; i < _appointments.length; i += 1) {
            const currApp = _appointments[i];
            const currId = currApp.appointment_id;

            if (billedAppoinments.current.includes(currId))
              setAppointmentList((k) => [
                ...k.filter((p) => p.appointment_id !== currId),
                currApp,
              ]);
            Axios.post(`${API_ENDPOINT}/billing/link-share`, {
              appointment_id: currId,
            }).then(({ data }) => {
              if (data && data.status && data.status === 'success') {
                setAppointmentList((k) => [
                  ...k.filter((p) => p.appointment_id !== currId),
                  currApp,
                ]);
              }
              billedAppoinments.current.push(currId);
            });
          }
          setLastRefreshedTime(moment());
        }
      })
      .catch((err) => {
        showAlert('Fetching appointments failed', 'error');
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
        setShowLoading(false);
      });
  };

  const isLive = (opList) => opList?.[0]?.is_op_active;

  const isTodaysOp = (opDate) =>
    moment(opDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY');

  const getCheckinTimeRange = (
    appointmentDate,
    appointmentTime,
    type,
    scheduleStartsAt
  ) => {
    if (type === 'web') {
      const webCheckinEndsBefore = moment.duration('12:00:00');
      const startsAt = moment(`${appointmentDate} ${appointmentTime}`)
        .subtract(3, 'days')
        .format('DD/MM/YYYY hh:mm A');
      const endsAt = moment(`${appointmentDate} ${appointmentTime}`)
        .subtract(webCheckinEndsBefore)
        .format('DD/MM/YYYY hh:mm A');
      return `${startsAt} - ${endsAt}`;
    }
    const clinicCheckinStartsBefore = moment.duration('00:60:00');
    const clinicCheckinEndsBefore = moment.duration('00:15:00');
    const startsAt = moment(`${appointmentDate} ${scheduleStartsAt}`)
      .subtract(clinicCheckinStartsBefore)
      .format('DD/MM/YYYY hh:mm A');
    const endsAt = moment(`${appointmentDate} ${appointmentTime}`)
      .subtract(clinicCheckinEndsBefore)
      .format('DD/MM/YYYY hh:mm A');
    return (
      <div>
        <div className="_block">{startsAt}</div>
        <div className="_block">{endsAt}</div>
      </div>
    );
  };

  const is_time_extended = (_lastUpdatedTime, _opTime) =>
    moment(_lastUpdatedTime, 'hh:mm A').valueOf() >
    moment(_opTime, 'hh:mm A').valueOf();

  const getCurrentOPTime = (
    app_id,
    op_list,
    appointment_date,
    break_start,
    break_duration
  ) => {
    const date = parse(appointment_date, 'yyyy-MM-dd', new Date());
    if (!isToday(date)) {
      return {
        time: format(parseTime(op_list[0].initial_op_time), 'hh:mm a'),
        isBreak: false,
      };
    }
    const currentIndex = op_list.findIndex(
      (app) => app.appointment_id === app_id
    );

    const { time } = calculateTime(
      op_list
        .filter((row) => !['Done', 'Absent'].includes(row?.status))
        .slice(0, currentIndex + 1)
    );
    const { toAdd } = getBreakTime(break_start, break_duration);
    return {
      time: format(addMinutes(time, toAdd), 'hh:mm a'),
      isBreak: toAdd > 0,
    };
  };

  const getPendingCount = (app_id, op_list) => {
    const _appnt_index = op_list
      ?.filter((obj) => !['Done', 'Absent'].includes(obj?.status))
      ?.findIndex((appnt) => appnt?.appointment_id === app_id);
    return _appnt_index >= 0 ? (
      <span id="opToken">{`${zeroPad(_appnt_index, 3)}`}</span>
    ) : null;
  };

  const cancelAppointmentAction = (appointment_id, clinic_no, schedule_id) => {
    if (!appointment_id || !clinic_no) return false;
    setCancelAppointmentID(appointment_id);
    setCancelAppClinicNo(clinic_no);
    setCancelScheduleId(schedule_id);
    window.$('#action-modal').modal('show');
  };

  const cancelBooking = (confirmed) => {
    if (!confirmed || !cancelAppointmentID || !cancelAppClinicNo) return;
    const appmnt = appointmentList.find(
      (e) => e.appointment_id === cancelAppointmentID
    );
    if (!appmnt) return;
    const requestData = {
      appointment_id: cancelAppointmentID,
      clinic_no: cancelAppClinicNo,
      schedule_id: cancelScheduleId,
      chart_date: appmnt?.appointment_date,
      person_no,
    };
    setShowLoading(true);
    Axios.post(
      `${API_ENDPOINT}/PatientAppointments/web_admin/cancel_appointment_by_id_live`,
      requestData
    )
      .then(({ data: resData }) => {
        loadPatientBookings();
      })
      .catch((err) => {
        setShowLoading(false);
        showAlert('Cancelling appointment/s failed', 'error');
      });
  };

  const checkinChangeHandler = (check_in_status, appointment_id) => {
    if (typeof check_in_status !== 'boolean' || !appointment_id) return;
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/PatientAppointments/live_web_checkin`, {
      appointment_id,
      check_in_time: check_in_status ? moment().format('HH:mm:ss') : null,
      person_no: `UIN${person_no}`,
    })
      .then(({ data: resData }) => {
        loadPatientBookings();
      })
      .catch((err) => showAlert('Updating checkin status failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const forceRefresh = () => {
    loadPatientBookings();
  };

  useInterval(() => {
    setRefreshtimeFromNow(lastRefreshedTime?.fromNow() || '');
  }, 1000);

  useEffect(() => {
    loadPatientBookings();
  }, []);

  const loc = useGeolocation();

  return (
    <>
      <div className={cn('row')}>
        {Object.keys(patientDetails).length ? (
          <>
            <div className="col-md-5">
              <div className="hex-user p-10" style={{ borderRadius: '5px' }}>
                <div
                  className="hex-user-item"
                  style={{
                    backgroundColor: '#fff',
                    textTransform: 'capitalize',
                  }}
                >
                  {patientDetails.visit_status}
                </div>
                <br />
                <div className="hex-user-item">{patientDetails.person_no}</div>
                <div className="hex-user-item">
                  {patientDetails.date_of_birth
                    ? moment().diff(
                        moment(patientDetails.date_of_birth, 'YYYY-MM-DD'),
                        'years'
                      )
                    : ''}
                </div>
                <div className="hex-user-item">{patientDetails.sex}</div>
                <div className="hex-user-item">
                  {patientDetails.first_name} {patientDetails.last_name}
                </div>
                {patientDetails.phone_no ? (
                  <div className="hex-user-item">{patientDetails.phone_no}</div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="col-12">
              {hasOtherBookings ? (
                <p className="m-t-10">Found other bookings, to manage:</p>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {appointmentList.length
        ? appointmentList
            .sort((a, b) => a.appointment_id - b.appointment_id)
            .sort((a, b) =>
              moment(a.appointment_date).isAfter(moment(b.appointment_date))
                ? 1
                : -1
            )
            .map((item, index) => {
              const isOpLive = isLive(item?.op_list);
              const isCancelled = item.booking_status === 'cancelled';
              const opTime = getCurrentOPTime(
                item.appointment_id,
                item.op_list,
                item.appointment_date,
                item.break_start,
                item.break_duration
              );
              const isAbsent = item.is_patient_absent;
              const distance =
                loc.latitude && loc.longitude
                  ? getDistance(
                      { latitude: loc.latitude, longitude: loc.longitude },
                      {
                        latitude: item.location_lat,
                        longitude: item.location_lon,
                      },
                      1
                    )
                  : null;

              const pendingCount = !loading
                ? getPendingCount(item.appointment_id, item.op_list)
                : null;

              return (
                <div className="_grid _p-1 sm:_p-4  _grid-cols-2 lg:_grid-cols-8 _pt-1 sm:_pt-6 _pb-40 last:_pb-1 first:_pt-1 odd:_bg-sky-50">
                  <div className="_flex _col-span-2 md:_col-span-1  lg:_col-span-2 ">
                    <div className="_hidden md:_block">
                      <img
                        className="_h-24 _w-24 _rounded-full _object-cover"
                        src={
                          item.provider_pic
                            ? STORAGE_DOMAIN + item.provider_pic
                            : item.sex === 'F'
                            ? '/img/home_page/female-avatar.png'
                            : '/img/home_page/male-avatar.png'
                        }
                        alt=""
                      />
                      {!isCancelled && (
                        <div
                          onClick={() =>
                            cancelAppointmentAction(
                              item.appointment_id,
                              item.clinic_no,
                              item.schedule_id,
                              item.appointment_date
                            )
                          }
                          className="_rounded-md _w-fit _mx-auto _mt-3 hover:_bg-gray-800 _duration-300 _bg-gray-700 _px-2  _cursor-pointer _py-0 _text-white"
                        >
                          Cancel
                        </div>
                      )}
                    </div>
                    <div className="_w-full _p-2 sm:_mx-2 sm:_border-0">
                      <div className="_flex _items-end _justify-between">
                        <div className="sm:_text-xl _text-3xl _font-bold sm:_leading-4">
                          {item.provider_name}
                        </div>
                      </div>
                      <div className="_text-sm _flex _justify-between _items-start _text-slate-600">
                        <span>{item.speciality_name.replace('\\n', ' ')}</span>
                        {!isCancelled && (
                          <div
                            onClick={() =>
                              cancelAppointmentAction(
                                item.appointment_id,
                                item.clinic_no,
                                item.schedule_id,
                                item.appointment_date
                              )
                            }
                            className="_rounded-md sm:_hidden _w-fit hover:_bg-rose-700 _duration-300 _bg-slate-700 _px-2  _cursor-pointer _py-0 _text-white"
                          >
                            Cancel
                          </div>
                        )}
                      </div>
                      <div className="_my-2 _w-fit _rounded-sm _bg-slate-300 _px-2 _py-1 _text-xs _leading-3">
                        {item.clinic_name}
                      </div>
                      <div className="_text-xs _leading-3">
                        Pushpa Juncion, 673002
                      </div>
                      {item.location_lat && item.location_lon && (
                        <div
                          onClick={() =>
                            window.open(
                              `http://maps.google.com/maps?q=${item.location_lat},${item.location_lon}`
                            )
                          }
                          className="_my-4 _w-fit _text-white _cursor-pointer _rounded-md _bg-teal-500 _px-2 _py-1 _text-sm hover:_bg-teal-400"
                        >
                          <i className="fa fa-map-marker" /> Get directions on
                          google maps
                        </div>
                      )}
                      <div className="_my-1 _text-xs">
                        <span className="sm:_block _inline xl:_inline">
                          {' '}
                          General{' '}
                          <span className="_bg-slate-200 _px-1 _font-bold">
                            {' '}
                            {showCurrency(item.currency_symbol)}
                            {item.normal_consult_fee}
                          </span>
                        </span>
                        {item.tatkal_consult_fee > 0 && (
                          <span className="lg:_block _mx-2 sm:_mx-0 sm:_block _inline xl:_inline">
                            Priority:{' '}
                            <span className="_bg-slate-200 _px-1 _font-bold">
                              {' '}
                              {showCurrency(item.currency_symbol)}
                              {item.tatkal_consult_fee}
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="_text-xs _mt-4 sm:_text-base">
                        <a
                          href={`tel:${item.clinic_phone_no}`}
                          className="_bg-slate-200 sm:_text-base _text-lg _px-2 _text-gray-900 _py-1"
                        >
                          <i className="fa fa-phone-square" />{' '}
                          {item.clinic_phone_no}
                        </a>
                      </div>
                      <div className="_mt-4 _hidden md:_block">
                        <ul className="_flex _space-x-1">
                          {Object.keys(week).map((key) => (
                            <li
                              className={classNames(
                                '_flex _w-5 _items-center _justify-center _rounded-full  _p-1 _text-xs _leading-3 ',
                                {
                                  '_bg-indigo-950/80 _text-white': item[key],
                                  '_bg-slate-200 _text-slate-400': !item[key],
                                }
                              )}
                            >
                              {week[key]}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {item.booking_status === 'confirmed' ? (
                    <div className="_my-4 _p-2 _mx-2 sm:_mx-0 _col-span-2 md:_col-span-1 lg:_col-span-2 _border-2 _border-slate-200 _border-solid  sm:_border-0">
                      <div className="_text-sm _font-bold _text-green-800">
                        Free Revisit:{' '}
                        <span>{item.free_revisit_period} Days</span>
                      </div>
                      {item.has_web_checkin && (
                        <div className="_my-2 _flex _items-center _space-x-2">
                          <div>
                            <input
                              type="checkbox"
                              onChange={({ target: { checked } }) =>
                                checkinChangeHandler(
                                  checked,
                                  item.appointment_id
                                )
                              }
                              disabled={item.web_checkin_time}
                            />
                          </div>
                          <div className="_rounded-lg _bg-slate-100 _px-2 _py-1 _text-xs sm:_text-base">
                            {' '}
                            {item.web_checkin_time
                              ? `W-Checked in at: ${toAmPm(
                                  item.web_checkin_time
                                )}`
                              : 'W-Checkin'}
                            {!item.web_checkin_time && (
                              <div className="_text-xs _flex _flex-col">
                                {getCheckinTimeRange(
                                  item.appointment_date,
                                  item.slot_time,
                                  'web'
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {item.clinic_checkin_time && (
                        <div className="_my-2 _flex _items-center _space-x-2">
                          <div>
                            <input type="checkbox" disabled />
                          </div>
                          <div className="_rounded-lg _bg-slate-200 _px-2 _py-1 _text-xs sm:_text-base">
                            {' '}
                            C-Checked in at {toAmPm(item.clinic_checkin_time)}
                          </div>
                        </div>
                      )}
                      <div className="_my-2 _flex _items-center _space-x-2">
                        <div className="_invisible">
                          <input type="checkbox" disabled />
                        </div>
                        <div className="_flex _w-full _space-x-2 _overflow-x-auto _overflow-y-hidden">
                          <div className="_rounded-lg _bg-slate-200 _px-2 _py-1 _text-xs _font-bold sm:_text-sm">
                            {item.purpose}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="_col-span-2 md:_col-span-1 lg:_col-span-2 _my-4" />
                  )}
                  <div className="_flex _p-2  sm:_mt-9 sm:_w-36 lg:_mt-0 ">
                    <div
                      className={cn('_flex', {
                        '_flex-row-reverse sm:_flex-col':
                          item.print_token_display,
                        '_flex-col sm:_ml-0': !item.print_token_display,
                      })}
                    >
                      <div className="sm:_pl-0">
                        <div className="_w-full _rounded-md _bg-slate-200 _mb-1 _px-2 _py-0 _text-center _text-2xl _font-bold">
                          {' '}
                          {moment(item.appointment_date).format('DD-MM-YY')}
                        </div>
                        {item.print_token_display && (
                          <div
                            onClick={() => forceRefresh()}
                            className="_w-full _rounded-md _border-4 _my-1 _border-solid _border-slate-400 _p-1 _text-center"
                          >
                            <div className="_text-xl _font-bold">Token No</div>
                            <div className="_text-6xl _font-black">
                              {' '}
                              {item.token_no}
                            </div>
                          </div>
                        )}
                        <OPLive
                          onClick={() => forceRefresh()}
                          col={false}
                          isBreak={opTime?.isBreak}
                          isLive={isOpLive}
                          className={`sm:_mt-0 ${
                            isCancelled ? '_flex' : '_hidden'
                          } sm:_flex`}
                          isAway={item.isAway}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sm:_mt-9 lg:_mt-0 lg:_col-span-3">
                    <div className="_flex">
                      <div>
                        <div className="_flex ">
                          <i
                            className={cn(
                              'fa _mr-2 sm:_mr-3 fa-3x fa-clock-o sm:_hidden  ',
                              {
                                '_text-sky-600 fa-clock-o':
                                  !isCancelled && !isAbsent,
                                '_text-rose-600 fa-times-circle': isCancelled,
                                '_text-amber-600 fa-times-circle': isAbsent,
                              }
                            )}
                          />
                          {!isCancelled && (
                            <OPLive
                              onClick={() => forceRefresh()}
                              col={false}
                              isLive={isOpLive}
                              isBreak={opTime?.isBreak}
                              className="sm:_mt-0 _flex sm:_hidden "
                              isAway={item.isAway}
                            />
                          )}
                        </div>
                        <div className="_flex _items-center">
                          <i
                            className={cn('fa fa-2x sm:_hidden  ', {
                              _hidden: !isCancelled && !isAbsent,
                              '_text-rose-600 _mr-1 fa-times-circle':
                                isCancelled,
                              '_text-amber-600 _mr-1 fa-times-circle': isAbsent,
                            })}
                          />
                          <div
                            onClick={() => forceRefresh()}
                            className={cn(' ', {
                              '_text-sky-600 _text-4xl _font-bold':
                                !isCancelled && !isAbsent,
                              '_text-rose-600 _text-3xl _font-semibold':
                                isCancelled,
                              '_text-amber-600 _text-base my-2 lg:_text-3xl _font-semibold':
                                isAbsent,
                            })}
                          >
                            {' '}
                            {isCancelled
                              ? 'Cancelled'
                              : isAbsent
                              ? 'You are late'
                              : loading
                              ? '...'
                              : opTime?.time}
                          </div>
                        </div>
                        {!isCancelled && !isAbsent && (
                          <div className="_text-[26.7px] _font-bold _text-emerald-700">
                            {!loading && 'Pending'}{' '}
                            {loading ? '...' : pendingCount}
                          </div>
                        )}
                        {isCancelled && (
                          <div className="_text-lg _pl-4 _font-bold _text-rose-700">
                            by {item.modify_by}
                          </div>
                        )}
                        {!isCancelled && (
                          <>
                            <div
                              className="sm:_my-2 _hidden sm:_flex _w-40 _rounded-md hover:_bg-slate-500 _duration-300 _cursor-pointer _bg-slate-700 _justify-center _items-center _px-4 _py-1 _text-white md:_mx-0"
                              onClick={() => forceRefresh()}
                            >
                              {' '}
                              <i className="fa fa-refresh mr-2" /> Refresh
                            </div>
                            {refreshtimeFromNow ? (
                              <div className="_font-medium _hidden sm:_block _text-sm sm:_text-lg _text-slate-500">
                                Last updated {refreshtimeFromNow}
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="_col-span-2 sm:_hidden _px-2 sm:_px-6">
                    {!isCancelled && (
                      <>
                        <div
                          className="_mb-2 sm:_my-2 _py-3 _rounded-md hover:_bg-slate-500 _duration-300 _cursor-pointer _bg-slate-700 _flex _justify-center _items-center _px-4  _text-white md:_mx-0"
                          onClick={() => forceRefresh()}
                        >
                          {' '}
                          <i className="fa fa-refresh mr-2" /> Refresh
                        </div>
                        {refreshtimeFromNow ? (
                          <div className="_font-medium _text-sm sm:_text-lg _text-slate-500">
                            Last updated {refreshtimeFromNow}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                  {item.appointment_date === moment().format('yyyy-MM-DD') && (
                    <div className="_col-span-2 lg:_col-span-8 lg:_text-xl _text-center _font-bold _text-rose-500">
                      {getMessage(
                        distance,
                        item.avg_pat_time,
                        isKerala(item.pin_code),
                        pendingCount
                      )}
                    </div>
                  )}
                </div>
              );
            })
        : ''}
      <ModalBox />
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
      <ConfirmAction
        message="You are about to cancel the appointment. Are you sure?"
        onAction={cancelBooking}
      />
    </>
  );
};

const ModalBox = () => (
  <div
    aria-hidden="true"
    className="modal fade"
    id="confim-modal"
    role="dialog"
    tabIndex="-1"
  >
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <div className="modal-header" style={{ justifyContent: 'center' }}>
          <div className="modal-title" style={{ fontSize: '1.6rem' }}>
            Are you sure?
          </div>
        </div>
        <div className="modal-body text-center">
          <button
            aria-label="Close"
            className="btn btn-danger"
            data-dismiss="modal"
          >
            Yes
          </button>
          <button
            aria-label="Close"
            className="btn btn-info m-l-10"
            data-dismiss="modal"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default Live;

const zeroPad = (num, places) => String(num).padStart(places, '0');

const OPLive = ({ isLive, onClick, isBreak, col, className, isAway }) => (
  <div
    onClick={onClick}
    className={cn(
      '_mr-2 _flex _items-center _overflow-hidden _rounded-lg _p-1 _text-base sm:_text-xl _font-bold _text-white sm:_w-full sm:_flex-row',
      className,
      {
        _visible: isLive,
        _invisible: !isLive,
        '_flex-col': col,
        '_bg-purple-600 _justify-center': isAway,
        '_bg-amber-600': !isAway && isBreak,
        '_bg-rose-600': !isAway && !isBreak,
      }
    )}
  >
    {!isAway && (
      <div
        className={cn(
          '_flex _rounded _bg-white _px-2 _text-center  sm:_flex-row',
          {
            '_flex-col': col,
            '_text-purple-600': isAway,
            '_text-amber-600': !isAway && isBreak,
            '_text-rose-600': !isAway && !isBreak,
          }
        )}
      >
        <span> O </span> <span> N </span>
      </div>
    )}
    <div
      className={cn(
        '_flex _px-1 _text-center _leading-3 sm:_flex-row sm:_leading-normal',
        {
          '_flex-col': col,
        }
      )}
    >
      {isAway ? 'AWAY' : isBreak ? 'BREAK' : 'SEAT'}
    </div>
  </div>
);

function getMessage(distance, avgPatientTime, isKerala, pendingCount) {
  if (!distance) {
    if (isKerala)
      return 'നിങ്ങൾ അടുത്താണെങ്കിൽ, പെൻഡിങ് കൗണ്ട് 5 പേരിൽ കുറയുമ്പോൾ കാണിക്കുന്ന സമയം നോക്കി വരേണ്ടതാണ്.';
    return 'If you are nearby, please check the time & plan your journey, when the pending count is less than 5 people.';
  }

  const kms = Math.floor(distance / 1000);
  if (kms > 20)
    return 'You are far away, please check the time & plan your journey based on the pending count, when you are close.';
  const minutes = Number(avgPatientTime);
  const minutesToTravel = kms * 2;

  const patientToWait = Math.floor(minutesToTravel / avgPatientTime) + 2;
  const showPending = pendingCount > patientToWait || pendingCount === null;
  return `You are around ${kms} km away,  ${
    showPending
      ? 'please check the time & start your journey, when the pending count is less than ' +
        patientToWait +
        ' people.'
      : 'start your journey now.'
  }`;
}
